<template>
    <div class="row">
      <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box">                 
                <div :class="item.bg_gray ? 'box-white-fiche bg-gray':'box-white-fiche'" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">

                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->  

                    <!-- detail_indication start-->
                    <Indication  v-else-if="key == 'detail_indication' && item.show"/>
                    <!--detail_indication end -->
                    
                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->
                    
                    <!-- detail_MaSpac start --> 
                    <div class="content-onglets" v-else-if="key == 'detail_MaSpac' && item.show">
                        <table class="comparateur2 text-left"  > 
                            <tbody>                            
                                <tr colspan="3">
                                    <td class="bold border-top-gray"><strong>{{$t('TRS_AMM')}}</strong></td>
                                    <td class="border-top-gray">{{$t('ceesp_type_amm_'+data.gba.gba_type_amm)}}</td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                    <!-- detail_MaSpac end -->

                    <!--detail_Comparators start -->
                    <table class="comparateur2" v-else-if="key == 'detail_Comparators' && item.show" > 
                        <thead>
                            <tr>
                                <th width="33.33%">{{$t('Population')}}</th>
                                <th width="33.33%">{{$t('Applicant comparator/s')}}</th>
                                <th width="33.33%">{{$t('G-BA comparator/s')}}</th>
                            </tr>                        
                        </thead>
                        <tbody>                            
                            <tr v-for="(items ,keys) in comparator" :key="'comparator'+keys">                                   
                                <td><span v-html="items.population"></span></td>                    
                                <td><span v-html="items.applicant_comparator"></span></td>
                                <td><span v-html="items.gba_comparator"></span></td>                            
                            </tr>                        
                        </tbody>
                    </table>  
                    <!-- detail_Comparators end -->

                    <!-- Conclusion start --> 
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />    
                    <!-- ele_Conclusion end -->
                    
                    <!-- detail_GbaResultBenefitAssessment start-->                                         
                    <div class="content-onglets table" v-else-if="key == 'detail_GbaResultBenefitAssessment' && item.show" >                               
                        <!-- @todo
                                if (!empty($this->data['EvaluationEssaisCliniqueAspiration'])) {
                            require_once 'sub_detail/sub_clinical_trial.ctp';
                        } 
                        -->                           
                        <div v-if="data.gba.benefit_assessment || data.gba.gba_result_benefit_assessment.length > 0" >                                                          
                            <template v-if="data.gba['benefit_assessment'+suffix]">
                                <div  data-model="Gba" :data-field="'benefit_assessment'+suffix" :data-pk="data.gba.id"  :data-title="$t('iqwig_benefit_assessment')" >
                                    <span v-html="data.gba['benefit_assessment'+suffix]"></span>
                                </div>
                            </template>  
                            <template v-if="data.gba.gba_result_benefit_assessment.length > 0">
                                <div v-for="(item_result_benefit_assessment,key_result_benefit_assessment) in  data.gba.gba_result_benefit_assessment" :key="'GbaResultBenefitAssessment'+key_result_benefit_assessment">                           
                                    <div v-if="item_result_benefit_assessment['title'+suffix]" data-model="GbaResultBenefitAssessment" :data-field="'title'+suffix" :data-pk="data.gba.id"  :data-title="$t('iqwig_benefit_assessment')" style="color: #ff7e00;">
                                        <strong>{{item_result_benefit_assessment['title'+suffix]}}</strong>
                                    </div>
                                    <div v-if="item_result_benefit_assessment['text'+suffix]"  data-model="GbaResultBenefitAssessment" :data-field="'text'+suffix" :data-pk="data.gba.id" :data-title="$t('iqwig_benefit_assessment')">                                                    
                                        <span v-html="item_result_benefit_assessment['text'+suffix]"></span>
                                    </div>
                                    <template v-if="item_result_benefit_assessment['gba_result_benefit_assessment_endpoint'].length > 0">                                
                                        <a :key="'gba_result_benefit_assessment_endpoint_'+key_result_benefit_assessment" 
                                            :href="FULL_BASE_URL+'/pdf/result_benefit_assessment_endpoint/'+data.gba.id+'/gba/'+item_result_benefit_assessment['id']" target="_blank" rel="noopener" style="color: #000; float: right;">
                                            <em class="fa fa-download"></em>
                                        </a>
                                        <div class="" style=" margin-top: 15px;clear: both">
                                            <table class="comparateur2">
                                                <tbody>
                                                    <tr>
                                                        <th colspan="2" width="60%" style="text-align: left;" >
                                                            <p v-if=" item_result_benefit_assessment['title'+suffix]" style=" color: #ff7e00">{{ item_result_benefit_assessment['title'+suffix]}}</p>                                                            
                                                            <span v-if="item_result_benefit_assessment['nct_number']">{{ item_result_benefit_assessment['nct_number']}}</span>                                                
                                                        </th>                                            
                                                        <th width="20%">{{ $t('Probability of added benefit')}}</th>
                                                        <th width="20%">{{ $t('Extend of the added benefit')}}</th>
                                                    </tr>
                                                    <template v-for="(items_gba_rbae,keys_gba_rbae ) in gen_GbaResultBenefitAssessmentEndpoint(item_result_benefit_assessment['gba_result_benefit_assessment_endpoint'])">                                                   
                                                        <tr v-for="(value , keys_rba) in items_gba_rbae " :key="'gba_result_benefit_assessment_endpoint_'+keys_rba+'_'+keys_gba_rbae">                                                    
                                                            <th v-if="keys_rba == 0" width="20%" :rowspan="items_gba_rbae.length" style="vertical-align:middle">
                                                                {{value['type'] ? $t('gba.'+value['type']) : ""}}
                                                            </th>                                                    
                                                            <td width="40%" style="color: #ff7e00;">
                                                                {{value['endpoint'] ? value['endpoint'] : '-'}}                                                             
                                                            </td>
                                                            <td>
                                                                {{value['endpoint'] ? value['probability'] : '-'}}                                                             
                                                            </td>
                                                            <td>
                                                                {{value['endpoint'] ? $t('gba.'+value['added_benefit']).replaceAll('"', "&").replaceAll('gba.', '').replaceAll('&', '"') : '-'}}                                                             
                                                            </td>
                                                        </tr>    
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                    </template>                                
    
                                    <template v-if="item_result_benefit_assessment['mortality'+suffix]">
                                        <div :key="'Mortality_'+key_result_benefit_assessment"><strong> {{$t('gba.Mortality')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['mortality'+suffix]" v-html="item_result_benefit_assessment['mortality'+suffix]" :key="'Mortality_text'+key_result_benefit_assessment"></span>                                        
                                    </template> 

                                    <template v-if="item_result_benefit_assessment['morbidity'+suffix]">
                                        <div :key="'Morbidity_'+key_result_benefit_assessment"><strong> {{$t('gba.Morbidity')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['morbidity'+suffix]" v-html="item_result_benefit_assessment['morbidity'+suffix]" :key="'Morbidity_text'+key_result_benefit_assessment"></span>                                         
                                    </template> 

                                    <template v-if="item_result_benefit_assessment['quality_of_life'+suffix]">
                                        <div :key="'Quality_of_life_'+key_result_benefit_assessment"><strong> {{$t('gba.Quality_of_life')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['quality_of_life'+suffix]" v-html="item_result_benefit_assessment['quality_of_life'+suffix]" :key="'Quality_of_life_text'+key_result_benefit_assessment"></span>                                        
                                    </template> 

                                    <template v-if="item_result_benefit_assessment['adverse_ivents'+suffix]">
                                        <div :key="'Adverse_events_'+key_result_benefit_assessment"><strong> {{$t('gba.Advers_events')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['adverse_ivents'+suffix]" v-html="item_result_benefit_assessment['adverse_ivents'+suffix]" :key="'Adverse_events_text'+key_result_benefit_assessment"></span>                                        
                                    </template> 

                                    <template v-if="item_result_benefit_assessment['relevent_subgroups'+suffix]">
                                        <div :key="'Relevant_subgroups_'+key_result_benefit_assessment"><strong> {{$t('gba.Relevant subgroups')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['relevent_subgroups'+suffix]" v-html="item_result_benefit_assessment['relevent_subgroups'+suffix]" :key="'Relevant_subgroups_text'+key_result_benefit_assessment"></span>                                        
                                    </template> 

                                    <template v-if="item_result_benefit_assessment['risk_of_bias'+suffix]">
                                        <div :key="'Risk_of_bias_'+key_result_benefit_assessment"><strong> {{$t('gba.Risk of bias')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['risk_of_bias'+suffix]" v-html="item_result_benefit_assessment['risk_of_bias'+suffix]" :key="'Risk_of_bias_text'+key_result_benefit_assessment"></span>
                                    </template> 

                                    <template v-if="item_result_benefit_assessment['other'+suffix]">
                                        <div :key="'Other_'+key_result_benefit_assessment"><strong> {{$t('gba.other')}} </strong></div>
                                        <span v-if="item_result_benefit_assessment['other'+suffix]" v-html="item_result_benefit_assessment['other'+suffix]" :key="'Other_text'+key_result_benefit_assessment"></span>                                        
                                    </template> 

                                </div>
                            </template>
                        </div> 
                    </div>  
                    
                    <!-- detail_GbaResultBenefitAssessment end -->

                    <!-- keydocuments start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- keydocuments end-->

                    <!-- detail_project_history start -->
                    <table class="comparateur2" v-else-if="key == 'detail_project_history' && item.show" >                       
                        <thead>
                            <tr>
                                <th class="first"></th>
                                <th width="20%">Date</th>
                            </tr>
                        </thead>  
                        <tbody>
                            <tr v-if="data.gba.deadline_beginning">
                                <td>{{$t('gba.Beginning process')}}</td>
                                <td>{{ DDMMMYY(data.gba.deadline_beginning)}}</td>
                            </tr>
                            <tr v-if="data.gba.deadline_publication">
                                <td>{{$t('gba.Publication of the benefit assessment and the beginning of the written opinion procedure')}}</td>
                                <td>{{ DDMMMYY(data.gba.deadline_publication)}}</td>
                            </tr>
                            <tr v-if="data.gba.deadline_submit_statement">
                                <td>{{$t('gba.Deadline to submit a written statement')}}</td>
                                <td>{{ DDMMMYY(data.gba.deadline_submit_statement)}}</td>
                            </tr>
                            <tr v-if="data.gba.deadline_oral">
                                <td>{{$t('gba.Oral hearing')}}</td>
                                <td>{{ DDMMMYY(data.gba.deadline_oral)}}</td>
                            </tr>
                            <tr v-if="data.gba.deadline_resolution">
                                <td>{{$t('gba.Resolution / Décisions')}}</td>
                                <td>{{ DDMMMYY(data.gba.deadline_resolution)}}</td>
                            </tr>
                            <tr v-if="data.gba.deadline_expiration">
                                <td>{{$t('gba.Expiration of Resolution')}}</td>
                                <td>{{ DDMMMYY(data.gba.deadline_expiration)}}</td>                            
                            </tr>            

                        </tbody>
                    </table>
                    <!-- detail_project_history end -->

                    <!-- detail_commentfr Start -->
                    <div class="content-onglets"  v-else-if="key == 'detail_commentfr' && item.show" >
                        <p v-if="$i18n.locale=='de'"><span v-html="data.gba.commentde"></span></p>
                        <p v-else><span v-html="data.gba.commentfr"></span></p>
                    </div>
                    <!-- detail_commentfr end -->

                    
                    <!--Additional benefit of the pharmaceutical over appropriate comparative treatment start-->                     
                    <template  v-else-if="key == 'detail_gbaAdditionBenefit' && item.show ">
                        <div class="accordion accordion-table">                     
                            <table class="comparateur2 table-heading top" style="margin-bottom:0px;">
                                <tbody>
                                    <tr>
                                        <th class="no-border" width="39%">&nbsp;</th>                              
                                            <td v-if="not_empty_row['probability']" class="bg-white border-top-gray" width="10%">Probability</td>                                
                                            <td class="bg-white border-top-gray" width="10%">Added beneﬁt (AB)</td>                                            
                                        </tr>
                                </tbody>
                            </table>                 
                            <template v-if=" data.gba['gba_addition_benefits'] &&  data.gba['gba_addition_benefits'].length > 0">        
                                <div v-for="(items,keys) in data.gba['gba_addition_benefits']"  :key="'gba_addition_benefits'+keys" class="accordion-group" >                            
                                    <table class="comparateur2 table-heading border-top-gray" style="margin-bottom:0px;">
                                        <tbody>
                                            <tr>
                                                <th class="text-left bg-white" width="39%">{{items['title'+suffix] }}</th>                                                            
                                                <td v-if="not_empty_row['probability']" class="bg-white" width="10%">{{ items['probability']  ? $t(items['probability']): "-" }}</td>
                                                <td class="bg-white" width="10%">
                                                    <template v-if="items.gba_added_benefit " >
                                                        <span v-if=" items.gba_added_benefit['name'+$i18n.locale]">{{ items.gba_added_benefit['name'+$i18n.locale] }}</span>
                                                    </template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <a class="accordion-toggle text-blank " @click="gba_addition_benefits_toggle(keys)" >                                        
                                        <em :class="gba_addition_benefits_show[keys] == 1 ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                    </a>                                
                                    <div  class="accordion-body" v-if="gba_addition_benefits_show[keys] == 1 "> 
                                        <template v-if="items['text']">
                                            <strong class="black">{{$t('gba.addition_benefit')}}</strong>
                                            <p>
                                                {{$t('gba.gba_added_benefit')}} :                                                 
                                                <span v-if=" items.gba_added_benefit['name'+$i18n.locale]" class="text-orange">{{ items.gba_added_benefit['name'+$i18n.locale] }}</span>                                                
                                            </p>
                                            <span v-html="items['text'+suffix] ? items['text'+suffix] : ''"></span>                      
                                        </template>                                  
                                    </div><!--collapseOne-->
                                </div><!--accordion-group-->
                            </template>       
                        </div>    
                         <div class="clearfix"></div>
                    </template>
                    <!--Additional benefit of the pharmaceutical over appropriate comparative treatment end-->



                    <!-- detail_gba_patient_criteria start  -->
                    <table class="comparateur2"  v-else-if="key == 'detail_gba_patient_criteria' && item.show">                        
                        <thead>
                            <tr>
                                <th class="first">{{$t('population')}}</th>
                                <th width="15%">Number</th>
                            </tr>
                        </thead>                           
                        <tbody v-if="data.gba.number_patient">
                            <tr>
                                <td class="text-left"><span v-html="data.gba.number_patient"></span></td>
                                <td>{{data.gba.pop_number}}</td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="data.gba.gba_number_patient">
                            <tr v-for="(items,keys) in data.gba.gba_number_patient" :key="'gba_number_patient'+keys">
                                <td class="text-left"> 
                                    <span v-html="items['title'+suffix] ? items['title'+suffix]+'<br>' : ''"></span>
                                    <span v-html="items['text'+suffix]? items['title'+suffix]+'<br>' : ''"></span>                                 
                                </td>
                                <td>{{items.pop_number }}</td>
                            </tr>                            
                        </tbody>
                    </table>
                   

                   <!-- gba Conclusion start-->             
                    <div class="box-onglets detail margin15"  v-else-if="key == 'detail_gba_Conclusion' && item.show" > 
                        <div class="content-onglets table">                        
                            <table class="comparateur2 table-2column text-left" v-if="GbaConclusion_appropriate_comparator.length > 0 || GbaConclusion_company_submitted.length > 0 ">
                                <tbody>
                                    <tr>
                                        <td colspan="2"><strong class="text_orange"> {{$t('gba.appropriate_comparator')}}</strong></td>
                                    </tr> 
                                    <template v-if="GbaConclusion_appropriate_comparator.length > 0 ">
                                        <tr v-for="(value,keys) in GbaConclusion_appropriate_comparator" :key="'gba_conclusion_appropriate_comparator'+keys">                                            
                                            <td v-if="keys == 0" :rowspan="GbaConclusion_appropriate_comparator.length" style="width: 25%;">
                                                <strong>{{$t('gba.appropriate_comparator_s')}}</strong>
                                            </td>                                            
                                            <td style="border-right: 0px;">{{ value }}</td>
                                        </tr>    
                                    </template>     
                                    <template v-if="GbaConclusion_company_submitted.length > 0 ">
                                        <tr v-for="(value,keys) in GbaConclusion_company_submitted" :key="'gba_conclusion_company_submitted'+keys">                                           
                                            <td v-if="keys == 0"  :rowspan="GbaConclusion_company_submitted.length" style="width: 25%;">  
                                                <strong>{{ $t('gba.company_appropriate_comparator_s')}}</strong>
                                            </td>                                            
                                            <td style="border-right: 0px;">{{value}}</td>
                                        </tr> 
                                    </template>
                                </tbody>
                            </table>
                            <div class="space"></div>                          

                            <template v-if="GbaConclusion_primary_endpoint.length > 0">
                                <table  class="comparateur2 table-2column text-left">
                                    <tbody>
                                        <tr>
                                            <td colspan="2"><strong class="text_orange">{{ $t('gba.ConclusionPrimaryEndpoint') }}</strong></td>
                                        </tr>                                     
                                        <tr v-for="(value,keys) in GbaConclusion_primary_endpoint" :key="'gba_conclusion_primary_endpoint'+keys">
                                            <td style="border-right: 0px;"><span v-html="value"></span></td>
                                        </tr>                          
                                    </tbody>
                                </table>
                                <div class="space"></div>
                            </template>
                           
                            <template v-if="data.gba['conclusion_acceptance_iqwig'+suffix] || data.gba['conclusion_acceptance_gba'+suffix]">
                                <table class="comparateur2 table-2column text-left">
                                    <tbody>
                                        <tr>
                                            <td colspan="2"><strong class="text_orange">{{$t('gba.conclusion_acceptance')}}</strong></td>
                                        </tr>                                        
                                        <tr v-if="data.gba['conclusion_acceptance_iqwig'+suffix]" >
                                            <td style="width: 25%;"><strong>{{ $t('gba.conclusion_acceptance_form_iqwig')}}</strong></td>
                                            <td><span v-html="data.gba['conclusion_acceptance_iqwig'+suffix] ? data.gba['conclusion_acceptance_iqwig'+suffix] : '-'" ></span></td>                                            
                                        </tr>                                    
                                        <tr v-if="data.gba['conclusion_acceptance_gba'+suffix]  ">
                                            <td><strong>{{ $t('gba.conclusion_acceptance_form_gba') }}</strong></td>                                            
                                            <td><span v-html="data.gba['conclusion_acceptance_gba'+suffix] ? data.gba['conclusion_acceptance_gba'+suffix] : '-'" ></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="space"></div>
                            </template>
                            <template v-if="data.gba['conclusion_has'] != 0 || data.gba['conclusion_has_were_iqwig'+suffix] || data.gba['conclusion_has_were_gba'+suffix] ">                                
                                <table  class="comparateur2 table-2column text-left">
                                    <tbody>
                                        <tr v-if="data.gba['conclusion_has']">
                                            <td width="50%"><strong class="text_orange">{{ $t('gba.conclusion_has')}}</strong></td>
                                            <td>{{ data.gba['conclusion_has'] != 0 ? $t("yes_no_"+data.gba['conclusion_has']):'' }}</td>
                                        </tr>                                         
                                        <tr v-if="data.gba['conclusion_has_were_iqwig'+suffix]">
                                            <td><strong>{{ $t('gba.conclusion_has_were_iqwig')}}</strong></td>
                                            <td><span v-html="data.gba['conclusion_has_were_iqwig'+suffix] ? data.gba['conclusion_has_were_iqwig'+suffix] : '-'" ></span></td>
                                        </tr>                
                                        <tr v-if=" data.gba['conclusion_has_were_gba'+suffix]">
                                            <td><strong>{{ $t('gba.conclusion_has_were_gba')}}</strong></td>
                                            <td><span v-html="data.gba['conclusion_has_were_gba'+suffix] ? data.gba['conclusion_has_were_gba'+suffix] : '-'" ></span></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                                <div class="space"></div>
                            </template>
                            <template v-if="data.gba['conclusion_indirect_treatment'] != 0  || data.gba['conclusion_indirect_treatment_iqwig'] != 0  || data.gba['conclusion_indirect_treatment_gba'] != 0  ">
                                <table  class="comparateur2 table-2column text-left">
                                    <tbody>
                                        <tr v-if="data.gba['conclusion_indirect_treatment']">
                                            <td width="80%"><strong class="text_orange">{{ $t('gba.conclusion_indirect_treatment')}}</strong></td>
                                            <td>{{ data.gba['conclusion_indirect_treatment'] != 0 ? $t('yes_no_'+data.gba['conclusion_indirect_treatment']) : '-' }}</td>
                                        </tr>                                     
                                        <tr v-if="data.gba['conclusion_indirect_treatment_iqwig']">
                                            <td><strong>{{ $t('gba.conclusion_indirect_treatment_iqwig', true) }}</strong></td>
                                            <td>{{ data.gba['conclusion_indirect_treatment_iqwig'] != 0 ? $t('yes_no_'+data.gba['conclusion_indirect_treatment_iqwig']) : "-"}} </td>
                                        </tr>
                                        <tr v-if="data.gba['conclusion_indirect_treatment_gba']">
                                            <td><strong>{{$t('gba.conclusion_indirect_treatment_gba')}}</strong></td>
                                            <td>{{data.gba['conclusion_indirect_treatment_gba'] != 0 ?  $t('yes_no_'+data.gba['conclusion_indirect_treatment_gba']) : "-"}}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <div class="space"></div>
                            </template>
                        </div>
                    </div>
            
                   <!--gba Conclusion end-->
                    

                    <!-- detail_gbaRequirement start-->
                    <div class="content-onglets"  v-else-if="key == 'detail_gbaRequirement' && item.show" style="display:block">                        
                        <p v-html="data.gba['gba_requirements_'+$i18n.locale]"></p>
                    </div>
                    <!-- detail_gbaRequirement end-->

                    <!-- detail_GbaCostTreatment Start -->
                    <div class="content-onglets table"  v-else-if="key == 'detail_GbaCostTreatment' && item.show">
                        <div v-for="(items, keys) in data.gba.gba_cost_treatment" :key="'GbaCostTreatment'+keys">
                            <div class="box-toggle open" >
                                <a class="title title-tb" style="cursor:pointer;"   @click="GbaCostTreatment[keys].show = !GbaCostTreatment[keys].show" >    
                                    <strong>{{  items['title'+suffix]  ? items['title'+suffix] :""}}</strong> <em :class="GbaCostTreatment[keys].show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>  

                                <section class="textJustify font11" v-if="GbaCostTreatment[keys].show" >
                              
                                    <template v-if="items['text'+suffix]" v-html="items['text'+suffix]"></template>
                                    <div><br><font class="text-orange" ><strong> {{($t('Costs after legally mandated rebates'))}}</strong></font></div> 
                                    <span v-if="items.costs_after_legally_mandated_rabates" v-html="items.costs_after_legally_mandated_rabates"></span>
                                    <template v-else>-</template>

                                    <template v-if="(item['duration'+suffix]) ||  (items.gba_cost_treatment_duration_pharma_eva && items.gba_cost_treatment_duration_pharma_eva.length > 0) || (items.gba_cost_treatment_duration_appro_com && items.gba_cost_treatment_duration_appro_com.length > 0)"   >                                    
                                        <div><br><font class="text-orange" ><strong>{{ $t('gba_duration')}}</strong></font></div>                                            
                                        <div class="textJustify" > 
                                          <span v-html="items['duration'+suffix] ? items['duration'+suffix] : ''"></span>
                                            <div v-if="items.gba_cost_treatment_duration_pharma_eva && items.gba_cost_treatment_duration_pharma_eva.length > 0" class="content-onglets collapse show" >
                                                <table class="comparateur2 tb-border">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="5" width="100%" class="first last" style="border:none" >{{$t('pharmaceutical_evaluated')}}</th>
                                                        </tr>
                                                        <tr>
                                                            <th width="20%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('mode_of_treatment')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('number_of_treatment')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('duration_per_cycle')}}</th>
                                                            <th width="20%" class="last" style="border:none" >{{$t('treatment_days')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(durationPE,key_durationPE) in items.gba_cost_treatment_duration_pharma_eva" :key="'durationPE'+key_durationPE">
                                                            <td>{{check_empty(durationPE.description)}}</td>
                                                            <td>{{check_empty(durationPE.mode)}}</td>
                                                            <td>{{check_empty(durationPE.number)}}</td>
                                                            <td>{{check_empty(durationPE.duration)}}</td>
                                                            <td>{{check_empty(durationPE.treatment_day)}}</td> 
                                                        </tr>                                                                    
                                                    </tbody> 
                                                </table> 
                                            </div>
                                    
                                            <div v-if="items.gba_cost_treatment_duration_appro_com && items.gba_cost_treatment_duration_appro_com.length > 0" class="content-onglets collapse show"  >
                                                <table class="comparateur2 tb-border">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="5" width="100%" class="first last" style="border:none"  >{{$t('appropirate_comparator')}}</th>
                                                        </tr>                                                               
                                                        <tr>
                                                            <th width="20%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('mode_of_treatment')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('number_of_treatment')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('duration_per_cycle')}}</th>
                                                            <th width="20%" class="last" style="border:none" >{{$t('treatment_days')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                            
                                                        <tr v-for=" (durationAC , key_durationAC ) in items.gba_cost_treatment_duration_appro_com" :key="'durationAC'+key_durationAC">
                                                            <td>{{ check_empty(durationAC.description)}}</td>
                                                            <td>{{ check_empty(durationAC.mode)}}</td>
                                                            <td>{{ check_empty(durationAC.number)}}</td>
                                                            <td>{{ check_empty(durationAC.duration)}}</td>
                                                            <td>{{ check_empty(durationAC.treatment_day)}}</td> 
                                                        </tr>                                                            
                                                    </tbody>
                                                </table>                                                 
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="items['consumption'+suffix] ||  (items.gba_cost_treatment_consumption_pharma_eva && items.gba_cost_treatment_consumption_pharma_eva.length > 0) || (items.gba_cost_treatment_consumption_appro_com && items.gba_cost_treatment_consumption_appro_com.length > 0)"   > 
                                        <div><br><font class="text-orange" ><strong>{{ $t('gba_consump')}}</strong></font></div>  
                                        <div class="textJustify" > 
                                            <span v-html="items['consumption'+suffix] ? items['consumption'+suffix]: ''"></span>
                                            <div v-if="items.gba_cost_treatment_consumption_pharma_eva && items.gba_cost_treatment_consumption_pharma_eva.length > 0" class="content-onglets collapse show"  >
                                                <table class="comparateur2 tb-border">                                                    
                                                    <thead>
                                                        <tr>
                                                            <th colspan="6" width="100%" class="first last" style="border:none" >{{$t('pharmaceutical_evaluated')}}</th>
                                                        </tr>                                                               
                                                        <tr>
                                                            <th width="17%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('dosage_strength')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('dosage_per_patient')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('number_amount_pack')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('consumption_treatment')}}</th>
                                                            <th class="last" style="border:none" >{{$t('average_consumption_strength')}}</th>
                                                        </tr>														
													</thead>
                                                    <tbody>                                                            
                                                        <tr v-for=" (consumptionPE , key_consumptionPE ) in items.gba_cost_treatment_consumption_pharma_eva" :key="'consumptionPE'+key_consumptionPE">
                                                            <td>{{ check_empty(consumptionPE.description)}}</td>
                                                            <td>{{ check_empty(consumptionPE.dosage)}}</td>
                                                            <td>{{ check_empty(consumptionPE.dosage_per_patient)}}</td>
                                                            <td>{{ check_empty(consumptionPE.number)}}</td>
                                                            <td>{{ check_empty(consumptionPE.consumption)}}</td> 
                                                            <td>{{ check_empty(consumptionPE.average)}}</td> 
                                                        </tr>                                                            
                                                    </tbody>
                                                </table>        
                                            </div>
                                            <div v-if="items.gba_cost_treatment_consumption_appro_com && items.gba_cost_treatment_consumption_appro_com.length > 0" class="content-onglets collapse show"  >
                                                <table class="comparateur2 tb-border">                                                    
                                                    <thead>
                                                        <tr>
                                                            <th colspan="5" width="100%" class="first last"  style="border:none" >{{$t('appropirate_comparator')}}</th>
                                                        </tr>                                                               
                                                        <tr>
                                                            <th width="17%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('dosage_strength')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('dosage_per_patient')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('number_amount_pack')}}</th>
                                                            <th width="17%" style="border:none" >{{$t('consumption_treatment')}}</th>
                                                            <th class="last" style="border:none" >{{$t('average_consumption_strength')}}</th>
                                                        </tr>														
													</thead>
                                                    <tbody>                                                            
                                                        <tr v-for=" (consumptionAC , key_consumptionAC ) in items.gba_cost_treatment_consumption_appro_com" :key="'consumptionPE'+key_consumptionAC">
                                                            <td>{{ check_empty(consumptionAC.description)}}</td>
                                                            <td>{{ check_empty(consumptionAC.dosage)}}</td>
                                                            <td>{{ check_empty(consumptionAC.dosage_per_patient)}}</td>
                                                            <td>{{ check_empty(consumptionAC.number)}}</td>
                                                            <td>{{ check_empty(consumptionAC.consumption)}}</td> 
                                                            <td>{{ check_empty(consumptionAC.average)}}</td> 
                                                        </tr>                                                            
                                                    </tbody>
                                                </table>        
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="items.consumption_bsa ||  (items.gba_cost_treatment_alternative_pharma_eva && items.gba_cost_treatment_alternative_pharma_eva.length > 0) || (items.gba_cost_treatment_alternative_appro_com && items.gba_cost_treatment_alternative_appro_com.length >0)"   > 
                                        <div><br><font class="text-orange" ><strong>{{items.alternative_for_specific}}</strong></font></div>
                                        <div class="textJustify">
                                            {{items.consumption_bsa}}
                                            <div v-if="items.gba_cost_treatment_alternative_pharma_eva && items.gba_cost_treatment_alternative_pharma_eva.length > 0" class="content-onglets collapse show" >
                                                <table class="comparateur2 tb-border">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="8" width="100%" class="first last" style="border:none"  >{{$t('pharmaceutical_evaluated')}}</th>
                                                        </tr>                                                                
                                                        <tr>
                                                            <th width="14%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('strength_per_ampoule')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('dosage_in_mg')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('consumption_one_time')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('consumption_bottle_or_tablet_per_treatment')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('number_of_treatment_day')}}</th>
                                                            <th  class="last" style="border:none" >{{$t('consumption_bottles_ortablet_per_year')}} </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                    
                                                        <tr v-for="(alternativePE, key_alternativePE) in items.gba_cost_treatment_alternative_pharma_eva" :key="'alternativePE'+key_alternativePE">
                                                            <td>{{check_empty(alternativePE.description)}}</td>
                                                            <td>{{check_empty(alternativePE.strength)}}</td> 
                                                            <td>{{check_empty(alternativePE.dosage)}}</td> 
                                                            <td>{{check_empty(alternativePE.consumption)}}</td> 
                                                            <td>{{check_empty(alternativePE.consumption_per_day)}}</td> 
                                                            <td>{{check_empty(alternativePE.number)}}</td> 
                                                            <td>{{check_empty(alternativePE.consumption_per_year)}}</td> 
                                                        </tr>                                                                    
                                                    </tbody>
                                                </table> 
                                            </div>
                                            <div v-if="items.gba_cost_treatment_alternative_appro_com && items.gba_cost_treatment_alternative_appro_com.length > 0" class="content-onglets collapse show" >
                                                <table class="comparateur2 tb-border">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="8" width="100%" class="first last" style="border:none"  >{{$t('appropirate_comparator')}}</th>
                                                        </tr>                                                                 
                                                        <tr> 
                                                            <th width="14%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('strength_per_ampoule')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('dosage_in_mg')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('consumption_one_time')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('consumption_bottle_or_tablet_per_treatment')}}</th>
                                                            <th width="14%" style="border:none" >{{$t('number_of_treatment_day')}}</th>
                                                            <th   class="last" style="border:none" >{{$t('consumption_bottles_ortablet_per_year')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                    
                                                        <tr v-for="(alternativeAC, key_alternativeAC) in items.gba_cost_treatment_alternative_appro_com" :key="'alternativeAC'+key_alternativeAC">
                                                            <td>{{check_empty(alternativeAC.description)}}</td>
                                                            <td>{{check_empty(alternativeAC.strength)}}</td> 
                                                            <td>{{check_empty(alternativeAC.dosage)}}</td> 
                                                            <td>{{check_empty(alternativeAC.consumption)}}</td> 
                                                            <td>{{check_empty(alternativeAC.consumption_per_day)}}</td> 
                                                            <td>{{check_empty(alternativeAC.number)}}</td> 
                                                            <td>{{check_empty(alternativeAC.consumption_per_year)}}</td> 
                                                        </tr>                                                                    
                                                    </tbody>
                                                </table> 
                                            </div>
                                        </div>
                                    </template>
                                   
                                    <template v-if="items['pharmaceutical'+suffix] || ( items.gba_cost_treatment_cost_pharma_eva &&  items.gba_cost_treatment_cost_pharma_eva.length > 0) || (items.gba_cost_treatment_cost_appro_com && items.gba_cost_treatment_cost_appro_com.length > 0)"   > 
                                        <div><br><font class="text-orange" ><strong>{{$t('gba_pharma')}}</strong></font></div>
                                        <div class="textJustify ">
                                            <span v-html="items['pharmaceutical'+suffix] ? items['pharmaceutical'+suffix] :''"></span>
                                            <div v-if="items.gba_cost_treatment_cost_pharma_eva && items.gba_cost_treatment_cost_pharma_eva.length > 0" class="content-onglets collapse show" >
                                                <table class="comparateur2 tb-border">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="5" width="100%" class="first last"  style="border:none" >{{$t('pharmaceutical_evaluated')}}</th>
                                                        </tr>                                                                
                                                        <tr>
                                                            <th width="20%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('strength')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('pack_size')}}</th>
                                                            <th width="20%" style="border:none" ><span v-html="$t('cost_pack')"></span></th>
                                                            <th width="20%" class="last" style="border:none" ><span v-html="$t('cost_after_legally')"></span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                    
                                                        <tr v-for="(costPE, key_costPE) in items.gba_cost_treatment_cost_pharma_eva" :key="'costPE'+key_costPE">
                                                            <td>{{check_empty(costPE.description)}}</td>
                                                            <td>{{check_empty(costPE.strength)}}</td> 
                                                            <td>{{check_empty(costPE.pack)}}</td> 
                                                            <td>{{check_empty(costPE.cost_pack)}}</td> 
                                                            <td>{{check_empty(costPE.cost_legally)}}</td> 
                                                        </tr>                                                                    
                                                    </tbody>
                                                </table> 
                                            </div>
                                            <div v-if="items.gba_cost_treatment_cost_appro_com && items.gba_cost_treatment_cost_appro_com.length > 0" class="content-onglets collapse show" >
                                                <table class="comparateur2 tb-border">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="5" width="100%" class="first last"  style="border:none" >{{$t('appropirate_comparator')}}</th>
                                                        </tr>                                                                 
                                                        <tr>
                                                            <th width="20%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('strength_mg')}}</th>
                                                            <th width="20%" style="border:none" >{{$t('pack_size')}}</th>
                                                            <th width="20%" style="border:none"  ><span v-html="$t('cost_pack')"></span></th>
                                                            <th width="20%" class="last" style="border:none" ><span v-html="$t('cost_after_legally')"></span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                    
                                                        <tr v-for="(costAC, key_costAC) in items.gba_cost_treatment_cost_appro_com" :key="'costAC'+key_costAC">
                                                            <td>{{check_empty(costAC.description)}}</td>
                                                            <td>{{check_empty(costAC.strength)}}</td> 
                                                            <td>{{check_empty(costAC.pack)}}</td> 
                                                            <td>{{check_empty(costAC.cost_pack)}}</td> 
                                                            <td>{{check_empty(costAC.cost_legally)}}</td> 
                                                        </tr>                                                                    
                                                    </tbody>
                                                </table> 
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="items['shi'+suffix] ||  (items.gba_cost_treatment_cost_additional_appro_com && items.gba_cost_treatment_cost_additional_appro_com.length > 0) || ( items.gba_cost_treatment_cost_additional_pharma_eva && items.gba_cost_treatment_cost_additional_pharma_eva.length > 0)"   > 
                                        <div><br><font class="text-orange" ><strong>{{$t('gba_addition')}}</strong></font></div>
                                        <div class="textJustify" > 
                                            <span v-html="items['shi'+suffix] "></span>
                                            <div v-if="items.gba_cost_treatment_cost_additional_pharma_eva && items.gba_cost_treatment_cost_additional_pharma_eva.length > 0" class="content-onglets collapse show" >
                                                <table class="comparateur2 tb-border">                                                    
                                                    <thead>
                                                        <tr>
                                                            <th colspan="6" width="100%" class="first last"  style="border:none" >{{$t('pharmaceutical_evaluated')}}</th>
                                                        </tr>                                                               
                                                        <tr>
                                                            <th width="16%" class="first" style="border:none"  >{{$t('description_of_therapy')}}</th>
                                                            <th width="16%" style="border:none"  >{{$t('type_of_benefit')}}</th>
                                                            <th width="16%" style="border:none"  >{{$t('cost_per_pack_unit')}}</th>
                                                            <th width="16%" style="border:none"  >{{$t('treatment_day_per_year')}}</th>
                                                            <th width="16%" style="border:none"  >{{$t('no_per_patient')}}</th>
                                                            <th class="last" style="border:none" >{{$t('annual_therapy_cost')}}</th>
                                                        </tr>
														
													</thead>
                                                    <tbody>                                                            
                                                        <tr v-for=" (additionalPE , key_additionalPE ) in items.gba_cost_treatment_cost_additional_pharma_eva" :key="'additionalPE'+key_additionalPE">
                                                            <td>{{ check_empty(additionalPE.description)}}</td>
                                                            <td>{{ check_empty(additionalPE.type_benefit)}}</td>
                                                            <td>{{ check_empty(additionalPE.cost_pack)}}</td>
                                                            <td>{{ check_empty(additionalPE.treatment_day)}}</td>
                                                            <td>{{ check_empty(additionalPE.no_patient)}}</td> 
                                                            <td>{{ check_empty(additionalPE.annual)}}</td> 
                                                        </tr>                                                            
                                                    </tbody>
                                                </table>        
                                            </div>
                                            <div v-if="items.gba_cost_treatment_cost_additional_appro_com && items.gba_cost_treatment_cost_additional_appro_com.length > 0" class="content-onglets collapse show"  >
                                                <table class="comparateur2 tb-border">                                                    
                                                    <thead>
                                                        <tr>
                                                            <th colspan="6" width="100%" class="first last"  style="border:none" >{{$t('appropirate_comparator')}}</th>
                                                        </tr>                                                               
                                                        <tr>
                                                            <th width="16%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                            <th width="16%" style="border:none" >{{$t('type_of_benefit')}}</th>
                                                            <th width="16%" style="border:none" >{{$t('cost_per_pack_unit')}}</th>
                                                            <th width="16%" style="border:none" >{{$t('treatment_day_per_year')}}</th>
                                                            <th width="16%" style="border:none" >{{$t('no_per_patient')}}</th>
                                                            <th  class="last" style="border:none" >{{$t('annual_therapy_cost')}}</th>
                                                        </tr>														
													</thead>
                                                    <tbody>                                                            
                                                        <tr v-for=" (additionalAC , key_additionalAC ) in items.gba_cost_treatment_cost_additional_appro_com" :key="'additionalAC'+key_additionalAC">
                                                            <td>{{ check_empty(additionalAC.description)}}</td>
                                                            <td>{{ check_empty(additionalAC.type_benefit)}}</td>
                                                            <td>{{ check_empty(additionalAC.cost_pack)}}</td>
                                                            <td>{{ check_empty(additionalAC.treatment_day)}}</td>
                                                            <td>{{ check_empty(additionalAC.no_patient)}}</td> 
                                                            <td>{{ check_empty(additionalAC.annual)}}</td> 
                                                        </tr>                                                            
                                                    </tbody>
                                                </table>        
                                            </div>
                                        </div>

                                    </template>
                                    
                                    <!-- <template v-if="!items['treatment_cost'+suffix] && !items['population'+suffix]   && !items['description'+suffix]  && !items['treatment_cost'+suffix]  && !items['treatment_cost_patient'+suffix]  
                                     && !items.gba_cost_treatment_annual_pharma_eva && !items.gba_cost_treatment_annual_appro_com  "   >

                                    </template> -->

                                     <template v-if="items['treatment_cost'+suffix] || 
                                     items['population'+suffix]  ||  
                                     items['description'+suffix]  ||  
                                     items['treatment_cost'+suffix]  ||  
                                     items['treatment_cost_patient'+suffix]  || 
                                     (items.gba_cost_treatment_annual_pharma_eva && items.gba_cost_treatment_annual_pharma_eva.length > 0) ||  
                                     (items.gba_cost_treatment_annual_appro_com && items.gba_cost_treatment_annual_appro_com.length > 0)  "   >

                                        <div><br><font class="text-orange"><strong>{{$t('gba_annual')}}</strong></font></div>
                                        <span v-html="items['treatment_cost'+suffix]"></span>
                                        <div v-if="items.gba_cost_treatment_annual_pharma_eva && items.gba_cost_treatment_annual_pharma_eva.length > 0" class="content-onglets collapse show"  >
                                            <table class="comparateur2 tb-border">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2" width="100%" class="first last"  style="border:none" >{{$t('pharmaceutical_evaluated')}}</th>
                                                    </tr>                                                                 
                                                    <tr>
                                                        <th width="50%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                        <th width="50%" class="last" style="border:none" ><span v-html="$t('annual_treatment_costs_per_patient')"></span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>                                                    
                                                    <tr v-for="(annualPE, key_annualPE) in items.gba_cost_treatment_annual_pharma_eva" :key="'annualPE'+key_annualPE">
                                                        <td>{{check_empty(annualPE.description)}}</td>
                                                        <td>{{check_empty(annualPE.annual)}}</td> 
                                                    </tr>                                                                    
                                                </tbody>
                                            </table> 
                                        </div>
                                        <div v-if="items.gba_cost_treatment_annual_appro_com && items.gba_cost_treatment_annual_appro_com.length > 0" class="content-onglets collapse show">
                                            <table class="comparateur2 tb-border">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2" width="100%" class="first last"  style="border:none" >{{$t('pharmaceutical_evaluated')}}</th>
                                                    </tr>                                                                 
                                                    <tr>
                                                        <th width="50%" class="first" style="border:none" >{{$t('description_of_therapy')}}</th>
                                                        <th width="50%" class="last" style="border:none" ><span v-html="$t('annual_treatment_costs_per_patient')"></span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>                                                    
                                                    <tr v-for="(annualAC, key_annualAC) in items.gba_cost_treatment_annual_appro_com" :key="'annualAC'+key_annualAC">
                                                        <td>{{check_empty(annualAC.description)}}</td>
                                                        <td>{{check_empty(annualAC.annual)}}</td> 
                                                    </tr>                                                                    
                                                </tbody>
                                            </table>                                             
                                        </div>                              
                                         <div class="padding30">                                            
                                            <div v-if="items['population'+suffix]"><strong>{{$t('gba_poppulation')}}</strong>
                                                <div class="textJustify" v-html="items['population'+suffix]"></div>
                                            </div>
                                            <div v-if="items['description'+suffix]"><strong>{{$t('gba_description')}}</strong>
                                                <div class="textJustify" v-html="items['description'+suffix]"></div>
                                            </div>
                                            <div v-if="items['treatment_cost_patient'+suffix]"><strong>{{$t('gba_annual_treatment')}}</strong>
                                                <div class="textJustify" v-html="items['treatment_cost_patient'+suffix]"></div>
                                            </div>
                                        </div>
                                    </template>
                                    

                                </section>
                            </div> 
                        </div>
                    
                    </div>
                    <!-- detail_GbaCostTreatment end -->

                    <!-- detail_clinical_trials -->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    
                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />                  

                </div>
            </template>
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import Conclusion from '../conclusion.vue'
import TreatmentLine from '../treatment_line.vue' 
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import {DDMMMYY, check_empty, check_detail_conclusion, key_documents} from '../../../utils'

export default {
    name : 'gba' ,
    components: {
        LeftSection,    
        EconomicEvaluation,
        Conclusion,
        TreatmentLine,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        InformationGeneral,
        Indication
    },
    data(){
        return {
            list_check_box : { 
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false,'bg_gray':false},
                detail_indication : {'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false,'bg_gray':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_MaSpac : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_Comparators : {'title' : 'gba.Comparators', 'show' : true, 'enable' : false, 'orange_text':false,'bg_gray':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true,'bg_gray':false},
                detail_GbaResultBenefitAssessment : {'title' : 'gba.Results of the benefit assessment', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_project_history : {'title' : 'gba.Project history', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_commentfr : {'title' : 'gba_comment', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_gbaAdditionBenefit : {'title' : 'gba_addition_benefit', 'show' : true, 'enable' : false, 'orange_text':false,'bg_gray':true},
                detail_gba_patient_criteria: {'title' : 'gba_patient_criteria', 'show' : true, 'enable' : false, 'orange_text':false,'bg_gray':false},
                detail_gba_Conclusion : {'title' : 'gba.Acceptance of clinical evidence', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_gbaRequirement : {'title' : 'gba_requirements', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_GbaCostTreatment : {'title' : 'gba_cost_treatment', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true ,'bg_gray':false},
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true,'bg_gray':false},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false ,'bg_gray':false},
            },
            not_empty_row : { 
                'probability' : false,
            },
            comparator : {}, 
            gba_addition_benefits_show : {},
            GbaCostTreatment : [],
            suffix : '',
            GbaConclusion_appropriate_comparator : {},
            GbaConclusion_company_submitted : {},
            GbaConclusion_check : false, 
            
                
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.gba){    
            
            this.suffix = this.$i18n.locale == 'de' ? 'de' : ''
            
            if(this.data.ma_date){ 
                this.list_check_box['detail_information_general'].enable = true
            }

            if((this.data['indication_de'] && this.$i18n.locale === 'de') || (this.$i18n.locale !== 'de' && this.data['indication_en'])){ 
                this.list_check_box['detail_indication'].enable = true
            }

            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }

            if (this.data.gba.gba_type_amm){
                this.list_check_box['detail_MaSpac'].enable = true
            }
            
            if(this.data.gba.gba_addition_benefits && this.data.gba.gba_addition_benefits.length > 0){        
                this.gba_comparator(this.data.gba.gba_addition_benefits)                    
                this.list_check_box['detail_Comparators'].enable = Object.keys(this.comparator).length > 0 ? true : false                                              
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) { 

                this.list_check_box['detail_Conclusion'].enable = true
            }

            if (this.data.gba.benefit_assessment
            // || this.data.evaluation_essais_clinique_aspiration                
            || this.data.gba['gba_result_benefit_assessment'].length > 0
            ){ 
                this.list_check_box['detail_GbaResultBenefitAssessment'].enable = true                    
            }        
             
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }      

            if(this.data.gba.deadline_beginning 
            || this.data.gba.deadline_publication
            || this.data.gba.deadline_submit_statement
            || this.data.gba.deadline_oral
            || this.data.gba.deadline_resolution 
            || this.data.gba.deadline_expiration 
            ){
                this.list_check_box['detail_project_history'].enable = true                    
            }

            if(this.data.gba['gba_addition_benefits'] && this.data.gba['gba_addition_benefits'].length > 0) {
                this.list_check_box['detail_gbaAdditionBenefit'].enable = true    

                let tmp_prob = false
                for(let key in this.data.gba['gba_addition_benefits']){
                    this.gba_addition_benefits_show[key] = 0
                    this.data.gba['gba_addition_benefits'][key]['probability'] ? tmp_prob = true : ""
                }                    
                this.not_empty_row['probability'] = tmp_prob
            }

        
            this.list_check_box['detail_gba_patient_criteria'].enable = this.gba_patient_criteria_check(this.data)
            
            this.gba_conclusion(this.data)
            if (Object.keys(this.GbaConclusion_company_submitted).length > 0 || 
            Object.keys(this.GbaConclusion_appropriate_comparator).length > 0 ||
            Object.keys(this.GbaConclusion_primary_endpoint).length > 0 || 
            this.GbaConclusion_check
                ){                     
                this.list_check_box['detail_gba_Conclusion'].enable = true
            }
                
            if (this.data.gba['gba_requirements_'+this.$i18n.locale]){

                this.list_check_box['detail_gbaRequirement'].enable = true       
            }           
            
            if (this.data.gba.gba_cost_treatment && this.data.gba.gba_cost_treatment.length > 0) {            
                this.list_check_box['detail_GbaCostTreatment'].enable = true           
                for(let key in this.data.gba.gba_cost_treatment){
                    this.GbaCostTreatment.push({'show':false}) 
                    console.log(key)
                }                    
            }
            if ( ( this.$i18n.locale == 'de' && this.data.gba.commentde ) || ( this.$i18n.locale != 'de' && this.data.gba.commentfr )){                   
                this.list_check_box['detail_commentfr'].enable = true           
            }            

            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true 
            } 
            
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0){
                this.list_check_box['detail_clinical_trials'].enable = true
            }
        }
    },
    methods : {   
        DDMMMYY,
        check_empty,
        check_detail_conclusion,
        key_documents,
        gba_conclusion(param){              
             
            //GbaConclusionAppropriate
            let GbaConclusionAppropriate_appropriate_comparator = []
            if (param.gba.gba_conclusion_appropriate && param.gba.gba_conclusion_appropriate.length > 0){                
                for (let key in param.gba.gba_conclusion_appropriate){
                    // console.log('appropriate_comparator...'+key)     
                    if(param.gba.gba_conclusion_appropriate[key]['appropriate_comparator'+this.suffix] )  {             
                       GbaConclusionAppropriate_appropriate_comparator[key] =  param.gba.gba_conclusion_appropriate[key]['appropriate_comparator'+this.suffix]
                    }
                }
            }
            if (GbaConclusionAppropriate_appropriate_comparator){
                this.GbaConclusion_appropriate_comparator = GbaConclusionAppropriate_appropriate_comparator 
            }

            //GbaConclusionCompanySubmitted
            let GbaConclusionCompanySubmitted_company_submitted  = []
            if (param.gba.gba_conclusion_company_submitted && param.gba.gba_conclusion_company_submitted.length > 0){                
                for (let key in param.gba.gba_conclusion_company_submitted){
                    // console.log('company_submitted...'+key)
                    if(param.gba.gba_conclusion_company_submitted[key]['company_submitted'+this.suffix] )  {
                        GbaConclusionCompanySubmitted_company_submitted[key] = param.gba.gba_conclusion_company_submitted[key]['company_submitted'+this.suffix]
                    }
                }
            }
            if (GbaConclusionCompanySubmitted_company_submitted) {
                this.GbaConclusion_company_submitted =   GbaConclusionCompanySubmitted_company_submitted 
            }

           //GbaConclusionPrimaryEndpoint
            let GbaConclusionPrimaryEndpoint_primary_endpoint  = []
            if (param.gba.gba_conclusion_primary_endpoint && param.gba.gba_conclusion_primary_endpoint.length > 0){                
                for (let key in param.gba.gba_conclusion_primary_endpoint){
                    // console.log('primary_endpoint...'+key)
                    if(param.gba.gba_conclusion_primary_endpoint[key]['primary_endpoint'+this.suffix] )  {
                        GbaConclusionPrimaryEndpoint_primary_endpoint[key] = param.gba.gba_conclusion_primary_endpoint[key]['primary_endpoint'+this.suffix]
                    }
                }
            }
            
            if (GbaConclusionPrimaryEndpoint_primary_endpoint) {
                this.GbaConclusion_primary_endpoint =   GbaConclusionPrimaryEndpoint_primary_endpoint 
            }

            if (param.gba['conclusion_acceptance_iqwig'+this.suffix]  ||
            param.gba['conclusion_acceptance_gba'+this.suffix] ||
            param.gba['conclusion_has_were_iqwig'+this.suffix] ||
            param.gba['conclusion_has_were_gba'+this.suffix] ||
            param.gba['conclusion_indirect_treatment'] ||
            param.gba['conclusion_has'] ||
            param.gba['conclusion_indirect_treatment_iqwig'] ||
            param.gba['conclusion_indirect_treatment_gba'] 
            ){
                this.GbaConclusion_check = true
            }
            
        },
        gba_patient_criteria_check(param){

            let is_show = false

            param.gba['number_patient'+this.suffix] ? is_show = true : ''     
            param.gba['target_population_company'+this.suffix] ? is_show = true : ''     

            if (param.gba['gba_number_patient'] && param.gba['gba_number_patient'].length > 0 ) {
                for (let key in param.gba['gba_number_patient']){
                    param.gba['gba_number_patient'][key]['target_population_company'+this.suffix] ? is_show = true : ''  
                    param.gba['gba_number_patient'][key]['title'+this.suffix] ? is_show = true : ''                     
                    param.gba['gba_number_patient'][key]['text'+this.suffix] ? is_show = true : ''                     
                    param.gba['gba_number_patient'][key]['pop_percent'] ? is_show = true : ''                     
                    param.gba['gba_number_patient'][key]['pop_number'] ? is_show = true : ''                     
                }
            }

            return is_show
        },
        gen_GbaResultBenefitAssessmentEndpoint(param){
            let GbaResultBenefitAssessmentEndpoint = {}
            for(let key in param) {
                if(!GbaResultBenefitAssessmentEndpoint[param[key]['type']]){
                    GbaResultBenefitAssessmentEndpoint[param[key]['type']] = []
                }
                GbaResultBenefitAssessmentEndpoint[param[key]['type']].push(param[key])
            }
            return GbaResultBenefitAssessmentEndpoint
        },        
        gba_addition_benefits_toggle(key){            
            if(this.gba_addition_benefits_show[key] === 1){
                this.gba_addition_benefits_show[key] = 0
            }else{
                this.gba_addition_benefits_show[key] = 1
            } 
            this.$forceUpdate();
        },
        gba_comparator(param){             
            let arr = {}
            let applicant_comparator = []
            let gba_comparator = []
            let cnt = 0
             
            for (let key in param) {
                arr[key] = {'population' : '', 'applicant_comparator':'','gba_comparator':''}                  
                for (let key2 in param[key]) {    
                    if ( param[key]['title'+this.suffix]){
                        arr[key]['population'] = param[key]['title'+this.suffix]     //gba_addition_benefits
                        cnt++
                    }
                    //arr[key]['population'] = this.$i18n.locale == 'de' ? param[key]['gba_addition_benefits_titlede']  : param[key]['gba_addition_benefits_title']  //gba_populations
                    applicant_comparator = [] 
                    gba_comparator = []                 
                    if (key2 == 'gba_addition_benefit_comparateur_submitted' && param[key][key2].length > 0) {
                        for (let key3 in param[key][key2]){
                                  if (this.$i18n.locale == 'de' && param[key][key2][key3]['text_de']) { 
                                      applicant_comparator.push(param[key][key2][key3]['text_de'])
                                  }else if (this.$i18n.locale != 'de' && param[key][key2][key3]['text']) { 
                                      applicant_comparator.push(param[key][key2][key3]['text'])
                                  }                         
                        }                
                        if (applicant_comparator){   
                            arr[key]['applicant_comparator'] =  applicant_comparator.join('<br/>')
                            cnt++
                        } 
                    }else if (key2 =='gba_addition_benefit_comparateur' && param[key][key2].length > 0) {
                        for (let key3 in param[key][key2]){
                            if (this.$i18n.locale == 'de' && param[key][key2][key3]['text_de']) { 
                                gba_comparator.push(param[key][key2][key3]['text_de']) 
                            }else if (this.$i18n.locale != 'de' && param[key][key2][key3]['text']) { 
                                gba_comparator.push(param[key][key2][key3]['text'])
                            }
                        }                
                        if (gba_comparator ) {
                            arr[key]['gba_comparator'] =   gba_comparator.join('<br/>') 
                            cnt++
                        }
                    }
                }                
                
            }                 
            if (cnt > 0) {
                this.comparator = arr  
            }
        }  
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return process.env.VUE_APP_URL_PRISMACCESS
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
			
    },
}
</script>

<style scoped>


a{
    cursor: pointer;
    color: #000;
}
.accordion-body { 
    background-color : #FEF0E6;
}
.box-toggle a:hover i {
    color: #ff6300;
}
.fa-angle-up {
    color : #ff6300;
}
table.comparateur2 tbody tr th {
    padding: 8px 3px;
    font-weight: normal;
}

.box-white-fiche h3 {
    text-align: justify;
}
 
table.table-heading.top {
    margin-left: 0;
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
}

.content-onglets {
    position: relative;
    width: 100%;
    text-align: left;
    color: #60636a; 
    margin-top: 15px;    
}
table.comparateur, table.comparateur2 {
    border-spacing: 0px !important;
}
.accordion-table .accordion-toggle.text-blank {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
    padding: 0 !important;
}

table.comparateur2 thead{    
    box-shadow: 0 2px 6px 0 rgba(229,229,230,0.8), inset 0 -1px 0 0 #e5e5e6;    
    position: relative;
    border-collapse : collapse;
    z-index: 10;
    -webkit-print-color-adjust:exact; 
    -webkit-filter:opacity(1);

}

table.comparateur2 thead th{
    font-weight: 600;
    position: relative; 
    text-align: center;
    font-size: 12px;
    color: #232426;
    padding: 8px 3px;
    background: white;
}
.content-onglets p {
    margin: 0 0 10px;
}

</style>